import React, {Component} from 'react';
import CustomButton from "./CustomButton";
import Divider from "./Divider";
import {Link} from "react-router-dom";
import Collapse from "react-bootstrap/Collapse";
import Nav from "react-bootstrap/Nav";
import NavLink from "react-bootstrap/NavLink";
import NavItem from "react-bootstrap/NavItem";

// Clase que crea el elemento del menú izquierdo.
class Sidebar extends Component {
    // Constructor del menú izquierdo.
    constructor(props) {
        super(props);
        this.state = {user: false, org: false, message: false, farm: false, phytosanitary: false, tool: false};
        this.activeId = props.activeId;
        this.toggleUser = this.toggleUser.bind(this);
        this.toggleOrg = this.toggleOrg.bind(this);
        this.toggleMessage = this.toggleMessage.bind(this);
        this.toggleFarm = this.toggleFarm.bind(this);
        this.togglePhytosanitary = this.togglePhytosanitary.bind(this);
        this.toggleTool = this.toggleTool.bind(this);
    }

    // Dibuja el menú izquierdo.
    render() {
        return (
                <Nav className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
                    {/* Sidebar - Brand */}
                    <div className="sidebar-brand d-flex align-items-center justify-content-center">
                        <NavLink href='/dashboard'>
                            <img src={process.env.PUBLIC_URL + "/img/logo_blanco_horizontal.png"} alt={"Logo oSIGris"} className="img-fluid"/>
                        </NavLink>
                    </div>

                    {/* Divider */}
                    <Divider additionalClass={"my-0"}/>

                    {/* Nav Item - Dashboard */}
                    <NavItem className={(this.activeId === "Dashboard" ? 'active' : '')}>
                        <NavLink href="/dashboard">
                            <i className="fas fa-fw fa-tachometer-alt"/>
                            <span>{this.props.strings.dashboardSidebar}</span>
                        </NavLink>
                    </NavItem>

                    {/* Divider */}
                    <Divider />

                    {/* Nav Item - User Collapse Menu */}
                    <NavItem onClick={this.toggleUser} className={(this.activeId === "UserInfo" || this.activeId === "UserManagement" ? 'active' : '')}>
                        <NavLink id="collapseUserTrigger" className="collapsed" to="#" data-toggle="collapse" data-target="#collapseMessage" aria-expanded="false" aria-controls="collapseUser">
                            <i className="fas fa-fw fa-users"/>
                            <span>{this.props.strings.userSidebar}</span>
                        </NavLink>
                        <Collapse in={this.state.user}>
                            <div id="collapseUser" aria-labelledby="headingUser" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <Link className="collapse-item" to="/user/userinfo">{this.props.strings.information}</Link>
                                    <Link className="collapse-item" to="/user/usermanagement">{this.props.strings.management}</Link>
                                </div>
                            </div>
                        </Collapse>
                    </NavItem>

                    {/* Nav Item - Organization Collapse Menu */}
                    <NavItem onClick={this.toggleOrg} className={(this.activeId === "OrganizationInfo" || this.activeId === "OrganizationManagement" ? 'active' : '')}>
                        <NavLink id="collapseOrgTrigger" className="collapsed" to="#" data-toggle="collapse" data-target="#collapseOrg" aria-expanded="false" aria-controls="collapseOrg">
                            <i className="fas fa-fw fa-building"/>
                            <span>{this.props.strings.orgSidebar}</span>
                        </NavLink>
                        <Collapse in={this.state.org}>
                            <div id="collapseOrg" aria-labelledby="headingOrg" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <Link className="collapse-item" to="/organization/organizationinfo">{this.props.strings.information}</Link>
                                    <Link className="collapse-item" to="/organization/organizationmanagement">{this.props.strings.management}</Link>
                                </div>
                            </div>
                        </Collapse>
                    </NavItem>

                    {/* Nav Item - Message Collapse Menu */}
                    <NavItem onClick={this.toggleMessage} className={(this.activeId === "MessageInfo" || this.activeId === "MessageManagement" ? 'active' : '')}>
                        <NavLink id="collapseMessageTrigger" className="collapsed" to="#" data-toggle="collapse" data-target="#collapseMessage" aria-expanded="false" aria-controls="collapseMessage">
                            <i className="fas fa-fw fa-envelope"/>
                            <span>{this.props.strings.messageSidebar}</span>
                        </NavLink>
                        <Collapse in={this.state.message}>
                            <div id="collapseMessage" aria-labelledby="headingMessage" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <Link className="collapse-item" to="/message/messageinfo">{this.props.strings.information}</Link>
                                    <Link className="collapse-item" to="/message/messagemanagement">{this.props.strings.management}</Link>
                                </div>
                            </div>
                        </Collapse>
                    </NavItem>

                    {/* Nav Item - Farm Collapse Menu */}
                    <NavItem onClick={this.toggleFarm} className={(this.activeId === "SeasonManagement" ? 'active' : '')}>
                        <NavLink id="collapseFarmTrigger" className="collapsed" to="#" data-toggle="collapse" data-target="#collapseFarm" aria-expanded="false" aria-controls="collapseFarm">
                            <i className="fas fa-fw fa-mountain"/>
                            <span>{this.props.strings.seasonSidebar}</span>
                        </NavLink>
                        <Collapse in={this.state.farm}>
                            <div id="collapseFarm" aria-labelledby="headingFarm" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <Link className="collapse-item" to="/season/seasonmanagement">{this.props.strings.management}</Link>
                                </div>
                            </div>
                        </Collapse>
                    </NavItem>

                    {/* Nav Item - Phytosanitary Collapse Menu */}
                    <NavItem onClick={this.togglePhytosanitary} className={(this.activeId === "PhytosanitaryInfo" || this.activeId === "PhytosanitaryManagement" ? 'active d-none' : 'd-none')}>
                        <NavLink id="collapsePhytosanitaryTrigger" className="collapsed" to="#" data-toggle="collapse" data-target="#collapsePhytosanitary" aria-expanded="false" aria-controls="collapsePhytosanitary">
                            <i className="fas fa-fw fa-syringe"/>
                            <span>{this.props.strings.phytosanitarySidebar}</span>
                        </NavLink>
                        <Collapse in={this.state.phytosanitary}>
                            <div id="collapsePhytosanitary" aria-labelledby="headingMessage" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <Link className="collapse-item" to="/phytosanitary/phytosanitaryinfo">{this.props.strings.information}</Link>
                                    <Link className="collapse-item" to="/phytosanitary/phytosanitarymanagement">{this.props.strings.management}</Link>
                                </div>
                            </div>
                        </Collapse>
                    </NavItem>

                    {/* Nav Item - Tool Collapse Menu */}
                    <NavItem onClick={this.toggleTool} className={(this.activeId === "ToolManagement" ? 'active' : '')}>
                        <NavLink id="collapseToolTrigger" className="collapsed" to="#" data-toggle="collapse" data-target="#collapseTool" aria-expanded="false" aria-controls="collapseTool">
                            <i className="fas fa-fw fa-cog"/>
                            <span>{this.props.strings.toolSidebar}</span>
                        </NavLink>
                        <Collapse in={this.state.tool}>
                            <div id="collapseTool" aria-labelledby="headingTool" data-parent="#accordionSidebar">
                                <div className="bg-white py-2 collapse-inner rounded">
                                    <Link className="collapse-item" to="/tool/toolmanagement">{this.props.strings.management}</Link>
                                </div>
                            </div>
                        </Collapse>
                    </NavItem>

                    {/* Divider */}
                    <Divider additionalClass={"d-none d-md-block"}/>

                    {/* Sidebar Toggler (Sidebar) */}
                    <div className="text-center d-none d-md-inline">
                        <CustomButton id={"sidebarToggle"} additionalClass={"rounded-circle border-0"} parentFunction={this.toggleNav}/>
                    </div>
                </Nav>
        );
    }

    // Abre o cierra el menú izquierdo cambiando su tamaño.
    toggleNav() {
        const nav = document.getElementById("accordionSidebar");
        const page_top = document.getElementById("root");
        if (nav.classList.contains("toggled")){
            page_top.classList.remove("sidebar-toggled");
            nav.classList.remove("toggled");
        } else {
            page_top.classList.add("sidebar-toggled");
            nav.classList.add("toggled");
        }
    }

    // Muestra u oculta el collapsible de usuarios y oculta el resto.
    toggleUser() {
        const user = document.getElementById("collapseUserTrigger");
        if (user.classList.contains("collapsed")){
            user.classList.remove("collapsed");
        } else {
            user.classList.add("collapsed");
        }
        this.setState({user: !this.state.user, org: false,
            message: false, farm: false, phytosanitary: false, tool: false});
    }

    // Muestra u oculta el collapsible de organización y oculta el resto.
    toggleOrg() {
        const org = document.getElementById("collapseOrgTrigger");
        if (org.classList.contains("collapsed")){
            org.classList.remove("collapsed");
        } else {
            org.classList.add("collapsed");
        }
        this.setState({user: false, org: !this.state.org,
            message: false, farm: false, phytosanitary: false, tool: false});
    }

    // Muestra u oculta el collapsible de mensajes y oculta el resto.
    toggleMessage() {
        const message = document.getElementById("collapseMessageTrigger");
        if (message.classList.contains("collapsed")){
            message.classList.remove("collapsed");
        } else {
            message.classList.add("collapsed");
        }
        this.setState({user: false, org: false,
            message: !this.state.message, farm: false, phytosanitary: false, tool: false});
    }

    // Muestra u oculta el collapsible de explotaciones y oculta el resto.
    toggleFarm() {
        const farm = document.getElementById("collapseFarmTrigger");
        if (farm.classList.contains("collapsed")){
            farm.classList.remove("collapsed");
        } else {
            farm.classList.add("collapsed");
        }
        this.setState({user: false, org: false,
            message: false, farm: !this.state.farm, phytosanitary: false, tool: false});
    }

    // Muestra u oculta el collapsible de tratamientos y oculta el resto.
    togglePhytosanitary() {
        const tool = document.getElementById("collapsePhytosanitaryTrigger");
        if (tool.classList.contains("collapsed")){
            tool.classList.remove("collapsed");
        } else {
            tool.classList.add("collapsed");
        }
        this.setState({user: false, org: false,
            message: false, farm: false, phytosanitary: !this.state.phytosanitary, tool: false});
    }

    // Muestra u oculta el collapsible de herramientas y oculta el resto.
    toggleTool() {
        const tool = document.getElementById("collapseToolTrigger");
        if (tool.classList.contains("collapsed")){
            tool.classList.remove("collapsed");
        } else {
            tool.classList.add("collapsed");
        }
        this.setState({user: false, org: false,
            message: false, farm: false, phytosanitary: false, tool: !this.state.tool});
    }
}

export default Sidebar;