import React, {Component} from "react";
import {datetimeToString, endPreLoad, refreshIfNeeded} from "../../../logic/functions/Basic";
import PageTitle from "../../component/PageTitle";
import Collapsible from "../../component/Collapsible";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import {Chart} from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import AsyncTypeahead from "react-bootstrap-typeahead/lib/components/AsyncTypeahead";
import {
    getUserListFilter, getStaffUserList, getAllUserList,
    getJobAsync, getUserFarms, removeUser,
    getUserDashboard, getJobAsyncResultAmazon,
    createLicenseServer, deleteLicense, getTypeKey
} from "../../../logic/functions/ServerPetitions";
import User from "../../../logic/objects/User";
import UserStaff from "../../../logic/objects/UserStaff";
import CustomButton from "../../component/CustomButton";
import Card from "../../component/Card";
import JobAsync from "../../../logic/objects/JobAsync";
import Input from "../../component/Input";
import Label from "../../component/Label";
import SysAdmin from "../../../logic/objects/SysAdmin";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import ReactAsyncTable from "react-async-table";
import DashboardGraph from "../../component/DashboardGraph";
import DashboardData from "../../../logic/objects/DashboardData";
import DashboardDay from "../../../logic/objects/DashboardDay";
import Flatpickr from "react-flatpickr";
// noinspection NpmUsedModulesInstalled
import {Spanish} from 'flatpickr/dist/l10n/es.js';
import KTP from "../../../logic/objects/KTP";
import Key from "../../../logic/objects/Key";
import UserMetadata from "../../../logic/objects/UserMetadata";
import TypeKey from "../../../logic/objects/TypeKey";

// Clase que contiene la información de la página de administración de usuarios.
// noinspection DuplicatedCode
class UserManagement extends Component {
    // Constructor de clase UserManagement.
    // noinspection DuplicatedCode
    constructor(props) {
        super(props);
        this.state = {typeKey: [], isFacturarLoading: false, isEliminarLoading: false, facturarOptions: [], facturarDateA: "",
            facturarDateB: "", facturarDateC: "", removeOptions: [], uploadOptions: [], uploadSrc: "", tablebody: [],
            showModalConfirmation: false, petitionended: false, maxamount: 0, page_size: 10, page: 0, isLoading: true,
            query: "", select: "", isDashLoading: false, dashOptions: [], dashboardData: "", exitPetition: false,
            isUserKeyLoading: false, userKeyOptions: [], selectedUserKey: "", userKey: null, userKeyDateB: undefined,
            userKeyDateC: undefined,  userKeyDateD: undefined, userKeyDateE: undefined};
        this.listaUsuarios = [];
        this.selectedUserIn = "";
        this.selectedUserDash = "";
        this.selectedUserRm = "";
        this.graphs = [];
        this.header = [{
            text: this.props.strings.tableId,
            dataField: 'id'
        }, {
            text: this.props.strings.tableUsername,
            dataField: 'username'
        }, {
            text: this.props.strings.tableEmail,
            dataField: 'email'
        }, {
            text: this.props.strings.tableName,
            dataField: 'name'
        }, {
            text: this.props.strings.tableSurname,
            dataField: 'surname'
        }, {
            text: this.props.strings.tableNIF,
            dataField: 'nif'
        }];

        this.headerSelectActionUser = () => (
            <div className="form-neg-margin">
                <label form="userSearchOption" className="control-label">Filtrar por </label>
                <select id="userSearchOption" defaultValue="username" className="filterOption form-control" onChange={() => {this.onSearchUser(document.getElementsByName('search')[0].value)}}>
                    <option value="username">Nombre de usuario</option>
                    <option value="email">Email</option>
                    <option value="name">Nombre</option>
                    <option value="surname">Apellidos</option>
                    <option value="nif">NIF</option>
                </select>
            </div>
        );
    }

    // Función que se realiza cuando se ha cargado el DOM, realiza las peticiones iniciales.
    componentDidMount = () => {
        document.title = this.props.strings.userTitle;
        Chart.register(zoomPlugin);

        getTypeKey().then(async typeKeyAsync => {
            const typeKeyJson = await typeKeyAsync.json();

            if (!typeKeyAsync.ok){
                const error = ((typeKeyJson && typeKeyJson.error) || typeKeyAsync.status);
                window.location.href = '/';
                return Promise.reject(error);
            }

            if (typeKeyJson.hasOwnProperty('data')){
                let auxArray = [];
                for (let i in typeKeyJson.data){
                    auxArray.push(new TypeKey(typeKeyJson.data[i]));
                }
                this.setState({typeKey: auxArray}, () => {
                   endPreLoad();
                });
            } else {
                refreshIfNeeded(typeKeyJson, null, this.props.showInfoModal);
            }
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            endPreLoad();
        });
    }

    // Función que se realiza al abandonar la página. Cancela las peticiones async.
    componentWillUnmount = () => {
        this.setState({exitPetition: true});
    }

    // Dibuja la página de gestión de usuarios.
    render = () => {
        let table = '';
        if (this.state.petitionended){
            table = <ReactAsyncTable keyField={"id"} columns={this.header} items={this.state.tablebody} isLoading={this.state.isLoading}
                                     currentPage={this.state.page+1} itemsPerPage={this.state.page_size} totalItems={this.state.maxamount}
                                     onChangePage={this.onChangePageUser} onSearch={this.onSearchUser} loader={this.tableLoaderUser}
                                     headerActions={this.headerSelectActionUser} useCache={false} delay={500}
                                     translations={{
                                         searchPlaceholder: this.props.strings.tableSearch,
                                         noDataText: this.props.strings.orgLicenseTableNotFound,
                                         requestFailedText: this.props.strings.tableSearchError,
                                         paginationFirst: this.props.strings.tablePaginationFirst,
                                         paginationLast: this.props.strings.tablePaginationLast
                                     }}/>;
        }

        return (
            <div className="container-fluid">

                {/* Page Heading */}
                <PageTitle text={this.props.strings.userManagementPageTitle}/>

                <div className="row">
                    <div className="col-12">
                        {/* Collapsible de facturar usuarios premium */}
                        <Collapsible triggerId={"collapseInvoicePremiumCardTrigger"} collapseId={"collapseInvoicePremiumCard"} cardTitle={this.props.strings.userInvoice} >
                            <div id="invoicePremiumFormLoader" className="form-loader d-none"/>
                            <form id="invoicePremiumForm">
                                <div className="row">
                                    <div className="col-12">
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.tableUser}</FormLabel>
                                            <AsyncTypeahead id="invoicePremiumUserMenu" placeholder={this.props.strings.asyncSearchUserPh} useCache={false} onInputChange={this.resetActivarOptions}
                                                            isLoading={this.state.isFacturarLoading} minLength={3} onSearch={this.getAutocompleteInvoiceUser} onChange={this.invoicePremiumFormFill}
                                                            delay={this.props.globals.getAsyncDelay()} filterBy={this.filterFunction} options={this.state.activarOptions} labelKey="loginname"
                                                            renderMenuItemChildren={(option) => (
                                                                <span>{(option.loginname !== "" ? option.loginname : option.name + " " + option.surname)}</span>
                                                            )} />
                                        </FormGroup>
                                    </div>
                                    <div className="col-12">
                                        <div className="row mb-4">
                                            <div className="col-4">
                                                <Card id={"invoicePremiumModuleA"} containerId={"invoicePremiumModuleAContainer"} cardColorClass={"border-left-success d-none"} titleColorClass={"text-success"} text={this.props.strings.userInvoiceCoop} iconClass={"fa-euro-sign"} />
                                            </div>
                                            <div className="col-4">
                                                <Card id={"invoicePremiumModuleB"} containerId={"invoicePremiumModuleBContainer"} cardColorClass={"border-left-success d-none"} titleColorClass={"text-success"} text={this.props.strings.userInvoiceOffline} iconClass={"fa-euro-sign"} />
                                            </div>
                                            <div className="col-4">
                                                <Card id={"invoicePremiumModuleC"} containerId={"invoicePremiumModuleCContainer"} cardColorClass={"border-left-success d-none"} titleColorClass={"text-success"} text={this.props.strings.userInvoicePhyto} iconClass={"fa-euro-sign"} />
                                            </div>
                                        </div>
                                        <div className="row mb-4">
                                            <div className="col-4">
                                                <Card id={"invoicePremiumModuleD"} containerId={"invoicePremiumModuleDContainer"} cardColorClass={"border-left-success d-none"} titleColorClass={"text-success"} text={this.props.strings.userInvoiceAnalysis} iconClass={"fa-euro-sign"} />
                                            </div>
                                            <div className="col-4">
                                                <Card id={"invoicePremiumModuleE"} containerId={"invoicePremiumModuleEContainer"} cardColorClass={"border-left-success d-none"} titleColorClass={"text-success"} text={this.props.strings.userInvoiceStock} iconClass={"fa-euro-sign"} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-3">
                                                <Card id={"userFarms"} containerId={"userFarmsContainer"} cardColorClass={"border-left-warning d-none"} titleColorClass={"text-warning"} text={this.props.strings.tableNSeason} iconClass={"fa-mountain"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div id="tableContainer" className="row mt-4 d-none">
                                <div className="col-12">
                                    {table}
                                </div>
                                <div id="userAmount" className="row ml-2 pl-1" />
                            </div>
                        </Collapsible>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {/* Collapsible de gestionar key de usuario */}
                        <Collapsible triggerId={"collapseUserKeyCardTrigger"} collapseId={"collapseUserKeyCard"} cardTitle={this.props.strings.userKey} >
                            <div id="userKeyFormLoader" className="form-loader d-none"/>
                            <form id="userKeyForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12">
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.tableUser}</FormLabel>
                                            <AsyncTypeahead id="userKeyMenu" placeholder={this.props.strings.asyncSearchUserPh}
                                                            ref={(ref) => this._typeaheadUserKey = ref} useCache={false} onInputChange={this.resetUserKeyOptions}
                                                            isLoading={this.state.isUserKeyLoading} minLength={3} onSearch={this.getAutocompleteUserKey} onChange={this.userKeyFormFill}
                                                            delay={this.props.globals.getAsyncDelay()} filterBy={this.filterFunction} options={this.state.userKeyOptions} labelKey="loginname"
                                                            renderMenuItemChildren={(option) => (
                                                                <span>{(option.loginname !== "" ? option.loginname : option.name + " " + option.surname)}</span>
                                                            )} />
                                        </FormGroup>
                                        <div id={"userKeyInfo"} className={"d-none"}>
                                            <FormLabel>{this.props.strings.tableNSeason}</FormLabel>
                                            <Input id={"userKeySeason"} type={"number"} ph={this.props.strings.licenseNSeasonPh} min={"0"} />

                                            <FormLabel className="mt-3">{this.props.strings.tableTypeKey}</FormLabel>
                                            <select id={"userKeyTypeKey"} className="form-control" />

                                            <label className="d-none mt-3">{this.props.strings.userPremiumOfflineDateTitle}</label>
                                            <div className="d-none row m-0">
                                                <Flatpickr id="userKeyDateB" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                                           value={this.state.userKeyDateB} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                                           onChange={date => {
                                                               this.setState({userKeyDateB: date});
                                                           }} />
                                                <div className={"col-1 p-0"}>
                                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearUserKeyDateB}>
                                                        <i className="fas fa-trash"/>
                                                    </CustomButton>
                                                </div>
                                            </div>

                                            <label className="mt-3">{this.props.strings.userPremiumPhytoDateTitle}</label>
                                            <div className="row m-0">
                                                <Flatpickr id="userKeyDateC" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                                           value={this.state.userKeyDateC} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                                           onChange={date => {
                                                               this.setState({userKeyDateC: date});
                                                           }} />
                                                <div className={"col-1 p-0"}>
                                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearUserKeyDateC}>
                                                        <i className="fas fa-trash"/>
                                                    </CustomButton>
                                                </div>
                                            </div>

                                            <label className="mt-3">{this.props.strings.userPremiumAnalysisDateTitle}</label>
                                            <div className="row m-0">
                                                <Flatpickr id="userKeyDateD" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                                           value={this.state.userKeyDateD} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                                           onChange={date => {
                                                               this.setState({userKeyDateD: date});
                                                           }} />
                                                <div className={"col-1 p-0"}>
                                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearUserKeyDateD}>
                                                        <i className="fas fa-trash"/>
                                                    </CustomButton>
                                                </div>
                                            </div>

                                            <label className="mt-3">{this.props.strings.userPremiumStockDateTitle}</label>
                                            <div className="row m-0">
                                                <Flatpickr id="userKeyDateE" className="form-control col-md-11 col-10" placeholder={this.props.strings.selectDatePh}
                                                           value={this.state.userKeyDateE} options={{dateFormat: "d-m-Y", locale: Spanish, position: "above"}}
                                                           onChange={date => {
                                                               this.setState({userKeyDateE: date});
                                                           }} />
                                                <div className={"col-1 p-0"}>
                                                    <CustomButton additionalClass={"btn-danger ml-2"} parentFunction={this.clearUserKeyDateE}>
                                                        <i className="fas fa-trash"/>
                                                    </CustomButton>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={"userKeyBtn"} className="d-none col-lg-4 col-md-12 flex-column-reverse">
                                        <div className="row d-flex justify-content-center">
                                            <CustomButton additionalClass={"btn-primary btn-icon-split mr-2"} parentFunction={this.createUserKey}>
                                                <span className="icon">
                                                    <i className="fas fa-check"/>
                                                </span>
                                                <span className="text">{this.props.strings.modalPrimaryButton}</span>
                                            </CustomButton>
                                            <CustomButton additionalClass={"btn-danger btn-icon-split"} parentFunction={this.removeUserKey}>
                                                <span className="icon">
                                                    <i className="fas fa-trash"/>
                                                </span>
                                                <span className="text">{this.props.strings.removeButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {/* Collapsible de dashboard de usuario indicado */}
                        <Collapsible triggerId={"collapseUserDashboardCardTrigger"} collapseId={"collapseUserDashboardCard"} cardTitle={this.props.strings.userDashboard} >
                            <div id="userDashboardFormLoader" className="form-loader d-none"/>
                            <form id="userDashboardForm">
                                <div className="row">
                                    <div className="col-12">
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.tableUser}</FormLabel>
                                            <AsyncTypeahead id="userDashboardUserMenu" placeholder={this.props.strings.asyncSearchUserPh} useCache={false} onInputChange={this.resetDashOptions}
                                                            isLoading={this.state.isDashLoading} minLength={3} onSearch={this.getAutocompleteDashboardUser} onChange={this.getUserDashboardPetition}
                                                            delay={this.props.globals.getAsyncDelay()} filterBy={this.filterFunction} options={this.state.dashOptions} labelKey="loginname"
                                                            renderMenuItemChildren={(option) => (
                                                                <span>{(option.loginname !== "" ? option.loginname : option.name + " " + option.surname)}</span>
                                                            )} />
                                        </FormGroup>
                                    </div>
                                    <div id="userSeasonsGraph" className="col-12 d-none">
                                        <div className="row">
                                            {/* User Season Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserSeasonAreaChartCardTrigger"} collapseId={"collapseUserSeasonAreaChartCard"} chartId={"userSeasonAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardSeason} cardShadow={false} btnFunction={this.resetUserSeasonChart} />
                                        </div>
                                        <div className="row">
                                            {/* Crop Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserCropAreaChartCardTrigger"} collapseId={"collapseUserCropAreaChartCard"} chartId={"userCropAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardCrop} cardShadow={false} btnFunction={this.resetUserCropChart} />
                                        </div>
                                        <div className="row">
                                            {/* Machine Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserMachineAreaChartCardTrigger"} collapseId={"collapseUserMachineAreaChartCard"} chartId={"userMachineAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardMachine} cardShadow={false} btnFunction={this.resetUserMachineChart} />
                                        </div>
                                        <div className="row">
                                            {/* Message Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserMessageAreaChartCardTrigger"} collapseId={"collapseUserMessageAreaChartCard"} chartId={"userMessageAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardMessage} cardShadow={false} btnFunction={this.resetUserMessageChart} />
                                        </div>
                                        <div className="row">
                                            {/* Notification Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserNotificationAreaChartCardTrigger"} collapseId={"collapseUserNotificationAreaChartCard"} chartId={"userNotificationAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardNotification} cardShadow={false} btnFunction={this.resetUserNotificationChart} />
                                        </div>
                                        <div className="row">
                                            {/* Infection Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserInfectionAreaChartCardTrigger"} collapseId={"collapseUserInfectionAreaChartCard"} chartId={"userInfectionAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardInfection} cardShadow={false} btnFunction={this.resetUserInfectionChart} />
                                        </div>
                                        <div className="row">
                                            {/* Fertilize Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserFertilizeAreaChartCardTrigger"} collapseId={"collapseUserFertilizeAreaChartCard"} chartId={"userFertilizeAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardFertilize} cardShadow={false} btnFunction={this.resetUserFertilizeChart} />
                                        </div>
                                        <div className="row">
                                            {/* Phytosanitary Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserPhytosanitaryAreaChartCardTrigger"} collapseId={"collapseUserPhytosanitaryAreaChartCard"} chartId={"userPhytosanitaryAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardPhytosanitary} cardShadow={false} btnFunction={this.resetUserPhytosanitaryChart} />
                                        </div>
                                        <div className="row">
                                            {/* Collect Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserCollectAreaChartCardTrigger"} collapseId={"collapseUserCollectAreaChartCard"} chartId={"userCollectAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardCollect} cardShadow={false} btnFunction={this.resetUserCollectChart} />
                                        </div>
                                        <div className="row">
                                            {/* Work Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserWorkAreaChartCardTrigger"} collapseId={"collapseUserWorkAreaChartCard"} chartId={"userWorkAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardWork} cardShadow={false} btnFunction={this.resetUserWorkChart} />
                                        </div>
                                        <div className="row">
                                            {/* Irrigation Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserIrrigationAreaChartCardTrigger"} collapseId={"collapseUserIrrigationAreaChartCard"} chartId={"userIrrigationAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardIrrigation} cardShadow={false} btnFunction={this.resetUserIrrigationChart} />
                                        </div>
                                        <div className="row">
                                            {/* Shepherd Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserShepherdAreaChartCardTrigger"} collapseId={"collapseUserShepherdAreaChartCard"} chartId={"userShepherdAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardShepherd} cardShadow={false} btnFunction={this.resetUserShepherdChart} />
                                        </div>
                                        <div className="row">
                                            {/* Note Area Chart */}
                                            <DashboardGraph resetZoom={this.props.strings.resetZoom} triggerId={"collapseUserNoteAreaChartCardTrigger"} collapseId={"collapseUserNoteAreaChartCard"} chartId={"userNoteAreaChart"} cardTitleClass={"txt-corp3"} cardTitle={this.props.strings.dashboardNote} cardShadow={false} btnFunction={this.resetUserNoteChart} />
                                        </div>
                                        <div className="row">
                                            <div className={"col-2 ml-auto text-right"}>
                                                <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={this.downloadDashboard}>
                                                <span className="icon">
                                                  <i className="fas fa-download"/>
                                                </span>
                                                    <span className="text">{this.props.strings.downloadButton}</span>
                                                </CustomButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {/* Collapsible de eliminar usuarios */}
                        <Collapsible triggerId={"collapseRemoveCardTrigger"} collapseId={"collapseRemoveCard"} cardTitle={this.props.strings.userRemoveUserTitle} >
                            <div id="removeFormLoader" className="form-loader d-none"/>
                            <form id="removeForm">
                                <div className="row">
                                    <div className="col-12">
                                        <FormGroup>
                                            <FormLabel>{this.props.strings.tableUser}</FormLabel>
                                            <AsyncTypeahead id="removePremiumUserMenu" placeholder={this.props.strings.asyncSearchUserPh} useCache={false} onInputChange={this.resetRemoveOptions}
                                                            isLoading={this.state.isEliminarLoading} minLength={3} onSearch={this.getAutocompleteRemoveUser} onChange={this.removeFormFill}
                                                            delay={this.props.globals.getAsyncDelay()} filterBy={this.filterFunction} options={this.state.removeOptions} labelKey="loginname"
                                                            renderMenuItemChildren={(option) => (
                                                                <span>{(option.loginname !== "" ? option.loginname : option.name + " " + option.surname)}</span>
                                                            )} />
                                        </FormGroup>
                                    </div>
                                    <div id="infoRemoveUser" className="col-12 ml-auto d-none">
                                        <div className="row mb-3">
                                            <div className="col-6">
                                                <Label for={"removeUserName"} text={this.props.strings.tableName}/>
                                                <Input id={"removeUserName"}/>
                                            </div>
                                            <div className="col-6">
                                                <Label for={"removeUserSurname"} text={this.props.strings.tableSurname}/>
                                                <Input id={"removeUserSurname"}/>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <div className="col-6">
                                                <Label for={"removeUserLoginname"} text={this.props.strings.tableUsername}/>
                                                <Input id={"removeUserLoginname"}/>
                                            </div>
                                            <div className="col-6">
                                                <Label for={"removeUserEmail"} text={this.props.strings.tableEmail}/>
                                                <Input id={"removeUserEmail"}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="buttonRemoveUser" className="col-lg-4 col-md-12 ml-auto d-none">
                                        <div className="justify-content-end d-flex">
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={this.removeUserConfirmation}>
                                                <span className="icon">
                                                  <i className="fas fa-user-slash"/>
                                                </span>
                                                <span className="text">{this.props.strings.removeButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12">
                        {/* Collapsible de descargar usuarios */}
                        <Collapsible triggerId={"collapseDownloadCardTrigger"} collapseId={"collapseDownloadCard"} cardTitle={this.props.strings.userDownloadUsers} >
                            <div id="downloadFormLoader" className="form-loader d-none"/>
                            <form id="downloadForm">
                                <div className="row">
                                    <div className="col-lg-8 col-md-12" />
                                    <div className="col-lg-4 col-md-12 d-flex flex-column-reverse">
                                        <div className="d-flex justify-content-end">
                                            <CustomButton additionalClass={"btn-primary btn-icon-split"} parentFunction={() => this.downloadUsers()}>
                                                <span className="icon">
                                                  <i className="fas fa-file-excel"/>
                                                </span>
                                                <span className="text">{this.props.strings.downloadButton}</span>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Collapsible>
                    </div>
                </div>

                {/* Modal confirmación de eliminar */}
                <Modal show={this.state.showModalConfirmation} backdrop="static" onHide={this.resetConfirmationModal} centered>
                    <ModalHeader closeButton>
                        <ModalTitle id="modal-confirmation-title" />
                    </ModalHeader>
                    <ModalBody>
                        <div id="modal-confirmation-content" />
                        <div className="row justify-content-center">
                            <div className="col-auto align-self-center">
                                <div id="modal-confirmation-operation" className="font-weight-bold" />
                            </div>
                            <div className="col-auto align-self-center">
                                <input id="modal-confirmation-result" type="number" className="form-control"
                                       placeholder={this.props.strings.operationConfirmation} />
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <CustomButton id={"modal-confirmation-button-cancel"} additionalClass={"btn-secondary"} text={this.props.strings.modalSecondaryButton} parentFunction={this.resetConfirmationModal}/>
                        <CustomButton id={"modal-confirmation-button-ok"} additionalClass={"btn-primary"} text={this.props.strings.modalPrimaryButton} parentFunction={this.hideConfirmationModal}/>
                    </ModalFooter>
                </Modal>
                {/* End of Modal confirmación de descarga */}
            </div>
        );
    }

    // Muestra el mensaje de cargando usuarios cuando se cambia de página en la tabla de usuarios de facturar usuarios.
    tableLoaderUser = () => {
        return <div>
            <h5 className="modal-title mb-3 text-center">{this.props.strings.userLoadingUsers}</h5>
            <div className="row justify-content-center">
                <div className="spinner-border text-success" role="status"><span className="sr-only">Loading...</span>
                </div>
            </div>
        </div>;
    }

    // Realiza el cambio de página de la tabla de usuarios de facturar usuario.
    onChangePageUser = (page) => {
        this.setState({tablebody: [], isLoading: true, page: page-1}, () => {
            this.getStaffUserListPage();
        });
    }

    // Realiza el filtrado de la tabla de usuarios de facturar usuario.
    onSearchUser = (query) => {
        this.setState({tablebody: [], isLoading: true, page: 0, query: query}, () => {
            this.getStaffUserListPage();
        });
    }

    // Realiza la petición de la página de usuarios relaccionados de facturación.
    getStaffUserListPage = () => {
        this.setState({tablebody: [], select: document.getElementById("userSearchOption").value}, () => {
            getStaffUserList(this.selectedUserIn.getId(), this.state.page_size, this.state.page, this.state.query, this.state.select).then(async userList => {
                const usersJson = await userList.json();
                this.setState({maxamount: parseInt(userList.headers.get("content-range").split(" ")[2])});

                if (!userList.ok) {
                    const error = userList.status;
                    return Promise.reject(error);
                }

                if (usersJson.hasOwnProperty('data')) {
                    let users = [];
                    for (let i in usersJson.data) {
                        users.push(new UserStaff(usersJson.data[i]));
                    }
                    this.fillUserListTable(users);
                } else {
                    refreshIfNeeded(usersJson, null, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
            });
        });
    }

    // Realiza el filtrado de usuarios por loginname, email, nombre o apellidos.
    filterFunction = (item, props) => {
        return item.loginname.toString().toLowerCase().includes(props.text.toString().toLowerCase())
            || item.email.toString().toLowerCase().includes(props.text.toString().toLowerCase())
            || item.name.toString().toLowerCase().includes(props.text.toString().toLowerCase())
            || item.surname.toString().toLowerCase().includes(props.text.toString().toLowerCase())
    }

    // Realiza la petición de usuarios con el texto introducido en el input de facturar.
    getAutocompleteInvoiceUser = (query) => {
        document.getElementById("invoicePremiumModuleAContainer").classList.add("d-none");
        document.getElementById("invoicePremiumModuleBContainer").classList.add("d-none");
        document.getElementById("invoicePremiumModuleCContainer").classList.add("d-none");
        document.getElementById("invoicePremiumModuleDContainer").classList.add("d-none");
        document.getElementById("invoicePremiumModuleEContainer").classList.add("d-none");
        document.getElementById("userFarmsContainer").classList.add("d-none");
        document.getElementById("tableContainer").classList.add("d-none");
        this.listaUsuarios = [];

        getUserListFilter(query, false).then(async userList => {
            this.setState({isFacturarLoading: true, page: 0});
            const userListJson = await userList.json();

            if (!userList.ok){
                const error = userList.status;
                return Promise.reject(error);
            }

            if (userListJson.hasOwnProperty('data') && userListJson.data.length > 0){
                let options = [];
                this.listaUsuarios = [];
                for (let i in userListJson.data){
                    const user = new User(userListJson.data[i]);
                    this.listaUsuarios.push(user);
                    options.push({id: user.getId(), loginname: user.getLoginname(), email: user.getEmail(),
                        name: user.getName(), surname: user.getSurname()});
                }
                this.setState({activarOptions: options});
            } else {
                refreshIfNeeded(userListJson, null, this.props.showInfoModal);
            }
            this.setState({isFacturarLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isFacturarLoading: false});
        });
    }

    // Establece el usuario buscado y muestra el resto de elementos.
    invoicePremiumFormFill = (items) => {
        this.selectedUserIn = "";
        for (let i in this.listaUsuarios){
            if (items.length > 0) {
                if (items[0].id === this.listaUsuarios[i].id) {
                    this.selectedUserIn = new User(this.listaUsuarios[i]);
                    break;
                }
            }
        }

        if (this.selectedUserIn !== ""){
            // Pide los usuarios relaccionados con el seleccionado y los añade a la tabla
            this.props.showLoaderModal(this.props.strings.loadingUsersMessage);
            this.getStaffUserListAndFarms().then(() => {
                document.getElementById("invoicePremiumModuleAContainer").classList.remove("d-none");
                document.getElementById("invoicePremiumModuleA").innerHTML = (this.selectedUserIn.isPremiumA() ? "SI" : "NO");
                document.getElementById("invoicePremiumModuleBContainer").classList.remove("d-none");
                document.getElementById("invoicePremiumModuleB").innerHTML = (this.selectedUserIn.isPremiumB() ? "SI" : "NO");
                document.getElementById("invoicePremiumModuleCContainer").classList.remove("d-none");
                document.getElementById("invoicePremiumModuleC").innerHTML = (this.selectedUserIn.isPremiumC() ? "SI" : "NO");
                document.getElementById("invoicePremiumModuleDContainer").classList.remove("d-none");
                document.getElementById("invoicePremiumModuleD").innerHTML = (this.selectedUserIn.isPremiumD() ? "SI" : "NO");
                document.getElementById("invoicePremiumModuleEContainer").classList.remove("d-none");
                document.getElementById("invoicePremiumModuleE").innerHTML = (this.selectedUserIn.isPremiumE() ? "SI" : "NO");
                document.getElementById("userFarmsContainer").classList.remove("d-none");
                document.getElementById("tableContainer").classList.remove("d-none");
                document.getElementById("invoicePremiumFormLoader").classList.add("d-none");
                this.props.hideLoaderModal();
            });
        }
    }

    // Resetea las opciones del typeahead de facturar usuario.
    resetActivarOptions = () => {
        this.setState({activarOptions: []});
    }

    // Realiza la petición de usuarios con el texto introducido en el input de gestión de key.
    getAutocompleteUserKey = (query) => {
        this.listaUsuarios = [];

        getUserListFilter(query, true).then(async userList => {
            this.setState({isUserKeyLoading: true});
            const userListJson = await userList.json();

            if (!userList.ok){
                const error = userList.status;
                return Promise.reject(error);
            }

            if (userListJson.hasOwnProperty('data') && userListJson.data.length > 0){
                let options = [];
                this.listaUsuarios = [];
                for (let i in userListJson.data){
                    const user = new User(userListJson.data[i]);
                    this.listaUsuarios.push(user);
                    options.push({id: user.getId(), loginname: user.getLoginname(), email: user.getEmail(),
                        name: user.getName(), surname: user.getSurname()});
                }
                this.setState({userKeyOptions: options});
            } else {
                refreshIfNeeded(userListJson, null, this.props.showInfoModal);
            }
            this.setState({isUserKeyLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isUserKeyLoading: false});
        });
    }

    // Establece el usuario buscado y muestra el resto de elementos.
    userKeyFormFill = (items) => {
        this.setState({selectedUserKey: "", userKeyDateB: undefined, userKeyDateC: undefined,
            userKeyDateD: undefined, userKeyDateE: undefined}, () => {
            for (let i in this.listaUsuarios){
                if (items.length > 0) {
                    if (items[0].id === this.listaUsuarios[i].getId()) {
                        let auxUser = new User(this.listaUsuarios[i]);
                        if (auxUser.getKey() === null || (auxUser.getKey() !== null && auxUser.getKey().getOrganization() === null
                            && !auxUser.getKey().getSubtype().isSupport())) {
                            document.getElementById("userKeySeason").value = "";
                            this.setState({selectedUserKey: auxUser, userKey: auxUser.getKey()}, () => {
                                document.getElementById("userKeyInfo").classList.remove("d-none");
                                document.getElementById("userKeyBtn").classList.remove("d-none");
                                document.getElementById("userKeyBtn").classList.add("d-flex");
                                let options = "";
                                    for (let j in this.state.typeKey){
                                        if (this.state.typeKey[j].getId() === TypeKey.ID_DEMO || this.state.typeKey[j].getId() === TypeKey.ID_MANAGER || this.state.typeKey[j].getId() === TypeKey.ID_INDIVIDUAL) {
                                            options += "<option value=" + this.state.typeKey[j].getId() + " " + (this.state.userKey !== null ? (this.state.typeKey[j].getId() === this.state.userKey.getSubtype().getId() ? "selected" : "") : "") + ">" + this.state.typeKey[j].getName() + "</option>";
                                        }
                                    }
                                    document.getElementById("userKeyTypeKey").innerHTML = options;
                                if (this.state.userKey !== null) {
                                    for (let j in this.state.userKey.getPremium()) {
                                        switch (this.state.userKey.getPremium()[j].getIdtp()) {
                                            case KTP.ID_OFFLINE_PREMIUM:
                                                this.setState({userKeyDateB: this.state.userKey.getPremium()[j].getEndDate().split(' ')[0]});
                                                document.getElementById("userKeyDateB").value = this.state.userKey.getPremium()[j].getEndDate().split(' ')[0];
                                                break;
                                            case KTP.ID_PHYTOSANITARY_PREMIUM:
                                                this.setState({userKeyDateC: this.state.userKey.getPremium()[j].getEndDate().split(' ')[0]});
                                                document.getElementById("userKeyDateC").value = this.state.userKey.getPremium()[j].getEndDate().split(' ')[0];
                                                break;
                                            case KTP.ID_ANALYSIS_PREMIUM:
                                                this.setState({userKeyDateD: this.state.userKey.getPremium()[j].getEndDate().split(' ')[0]});
                                                document.getElementById("userKeyDateD").value = this.state.userKey.getPremium()[j].getEndDate().split(' ')[0];
                                                break;
                                            case KTP.ID_STOCK_PREMIUM:
                                                this.setState({userKeyDateE: this.state.userKey.getPremium()[j].getEndDate().split(' ')[0]});
                                                document.getElementById("userKeyDateE").value = this.state.userKey.getPremium()[j].getEndDate().split(' ')[0];
                                                break;
                                            default:
                                                break;
                                        }
                                    }
                                    document.getElementById("userKeySeason").value = this.state.userKey.getNF();
                                }
                            });
                        } else {
                            if (auxUser.getKey().getSubtype().isSupport()){
                                this.props.showInfoModal(this.props.strings.licenseTypeErrorTitle, this.props.strings.licenseSupportTypeErrorBody);
                            } else {
                                this.props.showInfoModal(this.props.strings.licenseTypeErrorTitle, this.props.strings.licenseUserTypeErrorBody);
                            }
                            this.clearUserKeyFields();
                            document.getElementById("userKeyInfo").classList.add("d-none");
                            document.getElementById("userKeyBtn").classList.remove("d-flex");
                            document.getElementById("userKeyBtn").classList.add("d-none");
                        }
                        break;
                    }
                }
            }
        });
    }

    // Resetea las opciones del typeahead de editar key de usuario.
    resetUserKeyOptions = () => {
        this.setState({userKeyOptions: []});
    }

    // Limpia la fecha de premium offline de key de usuario.
    clearUserKeyDateB = () => {
        this.setState({userKeyDateB: undefined});
    }

    // Limpia la fecha de premium fitosanitarios de key de usuario.
    clearUserKeyDateC = () => {
        this.setState({userKeyDateC: undefined});
    }

    // Limpia la fecha de premium análisis de key de usuario.
    clearUserKeyDateD = () => {
        this.setState({userKeyDateD: undefined});
    }

    // Limpia la fecha de premium stock de key de usuario.
    clearUserKeyDateE = () => {
        this.setState({userKeyDateE: undefined});
    }

    // Manda la petición al servidor de generar una key de usuario si los campos son correctos.
    createUserKey = () => {
        let license = this.generateUserKey();
        let emptyDates = license.getPremium().filter((date) => {return date.enddate !== ""});
        if (license.nf <= 0){
            this.props.showInfoModal(this.props.strings.licenseCreateTechSeasonErrorTitle, this.props.strings.licenseCreateTechSeasonErrorBody);
        } else if (emptyDates.length === 0){
            this.props.showInfoModal(this.props.strings.licenseCreatePremiumErrorTitle, this.props.strings.licenseCreatePremiumErrorBody);
        } else {
            this.showUserKeyLoader();
            let json = {};
            json.data = license;
            createLicenseServer(json).then(async keyAsync => {
                const keyJson = await keyAsync.json();

                if (!keyAsync.ok) {
                    const error = keyAsync.status;
                    return Promise.reject(error);
                }

                if (keyJson.hasOwnProperty('data') && keyJson.data.hasOwnProperty('result') && keyJson.data['result'] === 'ok') {
                    this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                    this.hideUserKeyLoader();
                    this.clearUserKeyFields();
                } else {
                    refreshIfNeeded(keyJson, this.hideUserKeyLoader, this.props.showInfoModal);
                }
                document.getElementById("userKeyInfo").classList.add("d-none");
                document.getElementById("userKeyBtn").classList.remove("d-flex");
                document.getElementById("userKeyBtn").classList.add("d-none");
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                console.log(error);
                this.hideUserKeyLoader();
                document.getElementById("userKeyInfo").classList.add("d-none");
                document.getElementById("userKeyBtn").classList.remove("d-flex");
                document.getElementById("userKeyBtn").classList.add("d-none");
            });
        }
    }

    // Genera una nueva key para el usuario o edita la actual.
    generateUserKey = () => {
        if (this.state.userKey !== null){
            return this.editUserKey();
        } else {
            return this.generateNewUserKey();
        }
    }

    // Edita los campos de la key existente para el usuario.
    editUserKey = () => {
        let key = this.state.userKey;
        let auxDate;
        let licensePremium = [];
        if (document.getElementById("userKeyDateB").value !== "") {
            auxDate = document.getElementById("userKeyDateB").value;
            auxDate += " 00:00:00";
            let found = false;
            for (let i in key.getPremium()){
                if (key.getPremium()[i].getIdtp() === KTP.ID_OFFLINE_PREMIUM){
                    key.getPremium()[i].setEndDate(auxDate);
                    licensePremium.push(key.getPremium()[i]);
                    found = true;
                    break;
                }
            }
            if (!found){
                licensePremium.push(new KTP({idtp: KTP.ID_OFFLINE_PREMIUM, enddate: auxDate}));
            }
        }

        if (document.getElementById("userKeyDateC").value !== "") {
            auxDate = document.getElementById("userKeyDateC").value;
            auxDate += " 00:00:00";
            let found = false;
            for (let i in key.getPremium()){
                if (key.getPremium()[i].getIdtp() === KTP.ID_PHYTOSANITARY_PREMIUM){
                    key.getPremium()[i].setEndDate(auxDate);
                    licensePremium.push(key.getPremium()[i]);
                    found = true;
                    break;
                }
            }
            if (!found){
                licensePremium.push(new KTP({idtp: KTP.ID_PHYTOSANITARY_PREMIUM, enddate: auxDate}));
            }
        }

        if (document.getElementById("userKeyDateD").value !== "") {
            auxDate = document.getElementById("userKeyDateD").value;
            auxDate += " 00:00:00";
            let found = false;
            for (let i in key.getPremium()){
                if (key.getPremium()[i].getIdtp() === KTP.ID_ANALYSIS_PREMIUM){
                    key.getPremium()[i].setEndDate(auxDate);
                    licensePremium.push(key.getPremium()[i]);
                    found = true;
                    break;
                }
            }
            if (!found){
                licensePremium.push(new KTP({idtp: KTP.ID_ANALYSIS_PREMIUM, enddate: auxDate}));
            }
        }

        if (document.getElementById("userKeyDateE").value !== "") {
            auxDate = document.getElementById("userKeyDateE").value;
            auxDate += " 00:00:00";
            let found = false;
            for (let i in key.getPremium()){
                if (key.getPremium()[i].getIdtp() === KTP.ID_STOCK_PREMIUM){
                    key.getPremium()[i].setEndDate(auxDate);
                    licensePremium.push(key.getPremium()[i]);
                    found = true;
                    break;
                }
            }
            if (!found){
                licensePremium.push(new KTP({idtp: KTP.ID_STOCK_PREMIUM, enddate: auxDate}));
            }
        }

        key.setPremium(licensePremium);

        for (let i in this.state.typeKey){
            if (this.state.typeKey[i].getId().toString() === document.getElementById("userKeyTypeKey").value){
                key.setSubtype(new TypeKey(this.state.typeKey[i]));
                break;
            }
        }

        if (document.getElementById("userKeySeason").value !== ""
            && document.getElementById("userKeySeason").value > 0){
            key.setNF(parseInt(document.getElementById("userKeySeason").value));
        }

        return key;
    }

    // Genera la nueva key del usuario.
    generateNewUserKey = () => {
        let licensePremium = [];
        let auxDate;
        let auxLtp;
        if (document.getElementById("userKeyDateB").value !== "") {
            auxDate = document.getElementById("userKeyDateB").value;
            auxDate += " 00:00:00";
            auxLtp = new KTP({idtp: KTP.ID_OFFLINE_PREMIUM, enddate: auxDate});
            licensePremium.push(auxLtp);
        }

        if (document.getElementById("userKeyDateC").value !== "") {
            auxDate = document.getElementById("userKeyDateC").value;
            auxDate += " 00:00:00";
            auxLtp = new KTP({idtp: KTP.ID_PHYTOSANITARY_PREMIUM, enddate: auxDate});
            licensePremium.push(auxLtp);
        }

        if (document.getElementById("userKeyDateD").value !== "") {
            auxDate = document.getElementById("userKeyDateD").value;
            auxDate += " 00:00:00";
            auxLtp = new KTP({idtp: KTP.ID_ANALYSIS_PREMIUM, enddate: auxDate});
            licensePremium.push(auxLtp);
        }

        if (document.getElementById("userKeyDateE").value !== "") {
            auxDate = document.getElementById("userKeyDateE").value;
            auxDate += " 00:00:00";
            auxLtp = new KTP({idtp: KTP.ID_STOCK_PREMIUM, enddate: auxDate});
            licensePremium.push(auxLtp);
        }

        let auxSeason = "0";
        if (document.getElementById("userKeySeason").value !== ""
            && document.getElementById("userKeySeason").value > 0){
            auxSeason = document.getElementById("userKeySeason").value.toString();
        }

        let kt;
        for (let i in this.state.typeKey){
            if (this.state.typeKey[i].getId().toString() === document.getElementById("userKeyTypeKey").value){
                kt = new TypeKey(this.state.typeKey[i]);
                break;
            }
        }

        return new Key({user: new UserMetadata(this.state.selectedUserKey), subtype: kt, nf: auxSeason, premium: licensePremium, org: null});
    }

    // Enseña el loader del formulario para crear key de usuario.
    showUserKeyLoader = () => {
        document.getElementById("userKeyFormLoader").classList.remove("d-none");
        document.getElementById("userKeyForm").classList.add("d-none");
    }

    // Oculta el loader del formulario para crear key de usuario.
    hideUserKeyLoader = () => {
        document.getElementById("userKeyFormLoader").classList.add("d-none");
        document.getElementById("userKeyForm").classList.remove("d-none");
    }

    // Limpia los campos utilizados para crear una key de usuario.
    clearUserKeyFields = () => {
        this.setState({selectedUserKey: "", userKey: null, userKeyDateB: undefined,
            userKeyDateC: undefined, userKeyDateD: undefined, userKeyDateE: undefined, userKeyOptions: []});
        this._typeaheadUserKey.clear();
        this.listaUsuarios = [];
        this.clearUserKeyDateB();
        this.clearUserKeyDateC();
        this.clearUserKeyDateD();
        this.clearUserKeyDateE();
        document.getElementById("userKeySeason").value = "";
    }

    // Si existe key de usuario, realiza la petición de borrado.
    removeUserKey = () => {
        if (this.state.userKey !== null){
            let json = {};
            json.data = this.state.userKey;
            this.showUserKeyLoader();
            deleteLicense(json).then(async keyAsync => {
                const keyJson = await keyAsync.json();

                if (!keyAsync.ok) {
                    const error = keyAsync.status;
                    return Promise.reject(error);
                }

                this.clearUserKeyFields();
                document.getElementById("userKeyInfo").classList.add("d-none");
                document.getElementById("userKeyBtn").classList.remove("d-flex");
                document.getElementById("userKeyBtn").classList.add("d-none");
                if (keyJson.hasOwnProperty('data')) {
                    this.hideUserKeyLoader();
                    this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                } else {
                    refreshIfNeeded(keyJson, this.hideUserKeyLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.clearUserKeyFields();
                this.hideUserKeyLoader();
                this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                console.log(error);
            });
        }
    }

    // Realiza la petición de usuarios con el texto introducido en el input de usuarios para el dashboard.
    getAutocompleteDashboardUser = (query) => {
        this.listaUsuarios = [];
        this.setState({dashOptions: [], dashboardData: ""}, () => {
            document.getElementById("userSeasonsGraph").classList.add("d-none");
            if (this.graphs.length > 0){
                for (let i in this.graphs){
                    this.graphs[i].destroy();
                }
            }

            getUserListFilter(query, false).then(async userList => {
                this.setState({isDashLoading: true});
                const userListJson = await userList.json();

                if (!userList.ok){
                    const error = userList.status;
                    return Promise.reject(error);
                }

                if (userListJson.hasOwnProperty('data') && userListJson.data.length > 0){
                    let options = [];
                    this.listaUsuarios = [];
                    for (let i in userListJson.data){
                        const user = new User(userListJson.data[i]);
                        this.listaUsuarios.push(user);
                        options.push({id: user.getId(), loginname: user.getLoginname(), email: user.getEmail(),
                            name: user.getName(), surname: user.getSurname()});
                    }
                    this.setState({dashOptions: options});
                } else {
                    refreshIfNeeded(userListJson, null, this.props.showInfoModal);
                }
                this.setState({isDashLoading: false});
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.setState({isDashLoading: false});
            });
        });
    }

    // Realiza la petición del dashboard de usuario.
    getUserDashboardPetition = (items) => {
        this.selectedUserDash = "";
        if (this.graphs.length > 0){
            for (let i in this.graphs){
                this.graphs[i].destroy();
            }
        }
        document.getElementById("userSeasonsGraph").classList.add("d-none");
        for (let i in this.listaUsuarios){
            if (items.length > 0) {
                if (items[0].id === this.listaUsuarios[i].id) {
                    this.selectedUserDash = new User(this.listaUsuarios[i]);
                    break;
                }
            }
        }
        if (this.selectedUserDash !== "") {
            this.props.showLoaderModal(this.props.strings.loaderLoading);
            getUserDashboard(this.selectedUserDash.id).then(async dashboard => {
                const dashboardJson = await dashboard.json();

                if (!dashboard.ok) {
                    const error = dashboard.status;
                    return Promise.reject(error);
                }

                if (dashboardJson.hasOwnProperty('data')) {
                    const dashboardData = new DashboardData(dashboardJson.data);
                    this.setState({dashboardData: dashboardData});
                    document.getElementById("userSeasonsGraph").classList.remove("d-none");
                    this.graphs[0] = this.drawChart(document.getElementById("userSeasonAreaChart"), this.getAreaChartLabels(dashboardData.season),
                        this.props.strings.dashboardNSeason, this.getAreaChartData(dashboardData.season));
                    this.graphs[1] = this.drawChart(document.getElementById("userCropAreaChart"), this.getAreaChartLabels(dashboardData.incidence.crop),
                        this.props.strings.dashboardNCrop, this.getAreaChartData(dashboardData.incidence.crop));
                    this.graphs[2] = this.drawChart(document.getElementById("userMachineAreaChart"), this.getAreaChartLabels(dashboardData.machine),
                        this.props.strings.dashboardNMachine, this.getAreaChartData(dashboardData.machine));
                    this.graphs[3] = this.drawChart(document.getElementById("userMessageAreaChart"), this.getAreaChartLabels(dashboardData.message),
                        this.props.strings.dashboardNMessage, this.getAreaChartData(dashboardData.message));
                    this.graphs[4] = this.drawChart(document.getElementById("userNotificationAreaChart"), this.getAreaChartLabels(dashboardData.notification),
                        this.props.strings.dashboardNNotification, this.getAreaChartData(dashboardData.notification));
                    this.graphs[5] = this.drawChart(document.getElementById("userInfectionAreaChart"), this.getAreaChartLabels(dashboardData.incidence.infection),
                        this.props.strings.dashboardNInfection, this.getAreaChartData(dashboardData.incidence.infection));
                    this.graphs[6] = this.drawChart(document.getElementById("userFertilizeAreaChart"), this.getAreaChartLabels(dashboardData.incidence.fertilize),
                        this.props.strings.dashboardNFertilize, this.getAreaChartData(dashboardData.incidence.fertilize));
                    this.graphs[7] = this.drawChart(document.getElementById("userPhytosanitaryAreaChart"), this.getAreaChartLabels(dashboardData.incidence.phytosanitary),
                        this.props.strings.dashboardNPhytosanitary, this.getAreaChartData(dashboardData.incidence.phytosanitary));
                    this.graphs[8] = this.drawChart(document.getElementById("userCollectAreaChart"), this.getAreaChartLabels(dashboardData.incidence.collect),
                        this.props.strings.dashboardNCollect, this.getAreaChartData(dashboardData.incidence.collect));
                    this.graphs[9] = this.drawChart(document.getElementById("userWorkAreaChart"), this.getAreaChartLabels(dashboardData.incidence.work),
                        this.props.strings.dashboardNWork, this.getAreaChartData(dashboardData.incidence.work));
                    this.graphs[10] = this.drawChart(document.getElementById("userIrrigationAreaChart"), this.getAreaChartLabels(dashboardData.incidence.irrigation),
                        this.props.strings.dashboardNIrrigation, this.getAreaChartData(dashboardData.incidence.irrigation));
                    this.graphs[11] = this.drawChart(document.getElementById("userShepherdAreaChart"), this.getAreaChartLabels(dashboardData.incidence.shepherd),
                        this.props.strings.dashboardNShepherd, this.getAreaChartData(dashboardData.incidence.shepherd));
                    this.graphs[12] = this.drawChart(document.getElementById("userNoteAreaChart"), this.getAreaChartLabels(dashboardData.incidence.note),
                        this.props.strings.dashboardNNote, this.getAreaChartData(dashboardData.incidence.note));
                } else {
                    refreshIfNeeded(dashboardJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
                this.props.hideLoaderModal();
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.props.hideLoaderModal();
            });
        }
    }

    // Resetea las opciones del typeahead de dashboard de usuario.
    resetDashOptions = () => {
        this.setState({dashOptions: []});
    }

    // Resetea el zoom del gráfico de temporadas de usuario.
    resetUserSeasonChart = () => {
        this.graphs[0].resetZoom();
    }

    // Resetea el zoom del gráfico de cultivos de usuario.
    resetUserCropChart = () => {
        this.graphs[1].resetZoom();
    }

    // Resetea el zoom del gráfico de maquinaria de usuario.
    resetUserMachineChart = () => {
        this.graphs[2].resetZoom();
    }

    // Resetea el zoom del gráfico de mensajes de usuario.
    resetUserMessageChart = () => {
        this.graphs[3].resetZoom();
    }

    // Resetea el zoom del gráfico de notificaciones de usuario.
    resetUserNotificationChart = () => {
        this.graphs[4].resetZoom();
    }

    // Resetea el zoom del gráfico de infecciones de usuario.
    resetUserInfectionChart = () => {
        this.graphs[5].resetZoom();
    }

    // Resetea el zoom del gráfico de fertilizantes de usuario.
    resetUserFertilizeChart = () => {
        this.graphs[6].resetZoom();
    }

    // Resetea el zoom del gráfico de fitosanitarios de usuario.
    resetUserPhytosanitaryChart = () => {
        this.graphs[7].resetZoom();
    }

    // Resetea el zoom del gráfico de recolectas de usuario.
    resetUserCollectChart = () => {
        this.graphs[8].resetZoom();
    }

    // Resetea el zoom del gráfico de labores de usuario.
    resetUserWorkChart = () => {
        this.graphs[9].resetZoom();
    }

    // Resetea el zoom del gráfico de riegos de usuario.
    resetUserIrrigationChart = () => {
        this.graphs[10].resetZoom();
    }

    // Resetea el zoom del gráfico de pastoreos de usuario.
    resetUserShepherdChart = () => {
        this.graphs[11].resetZoom();
    }

    // Resetea el zoom del gráfico de notas de usuario.
    resetUserNoteChart = () => {
        this.graphs[12].resetZoom();
    }

    // Dibuja un gráfico.
    drawChart = (chart, labels, labelName, data) => {
        // noinspection JSCheckFunctionSignatures
        return new Chart(chart, {
            type: 'line',
            data: {
                labels: labels,
                datasets: [{
                    lineTension: 0.3,
                    backgroundColor: "rgba(78, 115, 223, 0.05)",
                    borderColor: "rgba(78, 115, 223, 1)",
                    pointRadius: 3,
                    pointBackgroundColor: "rgba(78, 115, 223, 1)",
                    pointBorderColor: "rgba(78, 115, 223, 1)",
                    pointHoverRadius: 3,
                    pointHoverBackgroundColor: "rgba(78, 115, 223, 1)",
                    pointHoverBorderColor: "rgba(78, 115, 223, 1)",
                    pointHitRadius: 10,
                    pointBorderWidth: 2,
                    label: labelName,
                    data: data
                }]
            },
            options: {
                maintainAspectRatio: false,
                layout: {
                    padding: {
                        left: 10,
                        right: 25,
                        top: 25,
                        bottom: 0
                    }
                },
                scales: {
                    x: {
                        grid: {
                            display: false,
                            drawBorder: false
                        },
                        ticks: {
                            maxTicksLimit: 7
                        },
                        time: {
                            unit: 'date'
                        }
                    },
                    y: {
                        grid: {
                            color: "rgb(234, 236, 244)",
                            drawBorder: false,
                            borderDash: [2],
                        },
                        ticks: {
                            maxTicksLimit: 5,
                            padding: 10,
                        }
                    }
                },
                transitions: {
                    zoom: {
                        animation: {
                            duration: 1000
                        }
                    }
                },
                plugins: {
                    zoom: {
                        // Container for zoom options
                        zoom: {
                            drag: {
                                enabled: true
                            },
                            wheel: {
                                enabled: false,
                            },
                            mode: 'x'
                        },
                        pan: {
                            enabled: true,
                            mode: 'x'
                        }
                    },
                    legend: {
                        display: false
                    },
                    tooltips: {
                        backgroundColor: "rgb(255,255,255)",
                        bodyFontColor: "#858796",
                        titleMarginBottom: 10,
                        titleFontColor: '#6e707e',
                        titleFontSize: 14,
                        borderColor: '#dddfeb',
                        borderWidth: 1,
                        xPadding: 15,
                        yPadding: 15,
                        displayColors: false,
                        intersect: false,
                        mode: 'index',
                        caretPadding: 10
                    }
                }
            }
        });
    }

    // Devuelve las labels de los gráficos.
    getAreaChartLabels = (dashboardDays) => {
        let result = [];
        for (let i in dashboardDays){
            result.push(dashboardDays[i].areaChartLabel());
        }
        return result;
    }

    // Devuelve los datos d elos gráficos.
    getAreaChartData = (dashboardDays) => {
        let result = [];
        for (let i in dashboardDays){
            result.push(dashboardDays[i].areaChartData());
        }
        return result;
    }

    // Crea el excel descargable de dashboard de usuario.
    downloadDashboard = () => {
        const ExcelJS = require('exceljs');
        const FileSaver = require('file-saver');
        let dashboardExcelTitle = this.props.strings.userDashboard + this.selectedUserDash.getVisibleName() + this.props.strings.excelFormat;
        let dashboardExcel = new ExcelJS.Workbook();

        this.createUserDashboardSeason(dashboardExcel);
        this.createUserDashboardCrop(dashboardExcel);
        this.createUserDashboardMachine(dashboardExcel);
        this.createUserDashboardMessage(dashboardExcel);
        this.createUserDashboardNotification(dashboardExcel);
        this.createUserDashboardInfection(dashboardExcel);
        this.createUserDashboardFertilize(dashboardExcel);
        this.createUserDashboardPhytosanitary(dashboardExcel);
        this.createUserDashboardCollect(dashboardExcel);
        this.createUserDashboardWork(dashboardExcel);
        this.createUserDashboardIrrigation(dashboardExcel);
        this.createUserDashboardShepherd(dashboardExcel);
        this.createUserDashboardNote(dashboardExcel);

        dashboardExcel.xlsx.writeBuffer().then(data => {
            // noinspection JSCheckFunctionSignatures, JSUnresolvedVariable
            const blob = new Blob([data], {type: this.blobType});
            // noinspection JSUnresolvedFunction
            FileSaver.saveAs(blob, dashboardExcelTitle);
        });
    }

    // Crea una nueva hoja del excel descargable de dashboard de usuario.
    createNewExcelDashboardPage = (worksheet, col1) => {
        worksheet.columns = [
            {key: 'date', width: 25},
            {key: 'number', width: 25}
        ];
        worksheet.getRow(1).values = [this.props.strings.tableDate, col1];
        ['A1', 'B1'].map(key => {
            worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: {argb: 'cccccc'}
            };
            worksheet.getCell(key).alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            return null;
        });
    }

    // Crea la hoja de incrementos de temporadas del dashboard del usuario a descargar.
    createUserDashboardSeason = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNSeason, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNSeason);
        for (let i in this.state.dashboardData.season) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.season[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de cultivos del dashboard del usuario a descargar.
    createUserDashboardCrop = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNCrop, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNCrop);
        for (let i in this.state.dashboardData.incidence.crop) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.incidence.crop[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de maquinaria del dashboard del usuario a descargar.
    createUserDashboardMachine = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNMachine, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNMachine);
        for (let i in this.state.dashboardData.machine) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.machine[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de mensajes del dashboard del usuario a descargar.
    createUserDashboardMessage = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNMessage, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNMessage);
        for (let i in this.state.dashboardData.message) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.message[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de notificaciones del dashboard del usuario a descargar.
    createUserDashboardNotification = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNNotification, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNNotification);
        for (let i in this.state.dashboardData.notification) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.notification[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de infecciones del dashboard del usuario a descargar.
    createUserDashboardInfection = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNInfection, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNInfection);
        for (let i in this.state.dashboardData.incidence.infection) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.incidence.infection[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de fertilizantes del dashboard del usuario a descargar.
    createUserDashboardFertilize = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNFertilize, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNFertilize);
        for (let i in this.state.dashboardData.incidence.fertilize) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.incidence.fertilize[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de fitosanitarios del dashboard del usuario a descargar.
    createUserDashboardPhytosanitary = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNPhytosanitary, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNPhytosanitary);
        for (let i in this.state.dashboardData.incidence.phytosanitary) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.incidence.phytosanitary[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de recolectas del dashboard del usuario a descargar.
    createUserDashboardCollect = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNCollect, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNCollect);
        for (let i in this.state.dashboardData.incidence.collect) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.incidence.collect[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de labores del dashboard del usuario a descargar.
    createUserDashboardWork = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNWork, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNWork);
        for (let i in this.state.dashboardData.incidence.work) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.incidence.work[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de riegos del dashboard del usuario a descargar.
    createUserDashboardIrrigation = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNIrrigation, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNIrrigation);
        for (let i in this.state.dashboardData.incidence.irrigation) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.incidence.irrigation[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de pstoreos del dashboard del usuario a descargar.
    createUserDashboardShepherd = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNShepherd, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNShepherd);
        for (let i in this.state.dashboardData.incidence.shepherd) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.incidence.shepherd[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Crea la hoja de incrementos de notas del dashboard del usuario a descargar.
    createUserDashboardNote = (dashboardExcel) => {
        let worksheet = dashboardExcel.addWorksheet(this.props.strings.userNNote, {
            views: [{
                activeCell: 'A1',
                showGridLines: true
            }]
        });
        this.createNewExcelDashboardPage(worksheet, this.props.strings.dashboardNNote);
        for (let i in this.state.dashboardData.incidence.note) {
            let dashboardDay = new DashboardDay(this.state.dashboardData.incidence.note[i]);
            worksheet.addRow([dashboardDay.date, dashboardDay.value]);
        }
    }

    // Realiza la petición de usuarios con el texto introducido en el input de eliminar.
    getAutocompleteRemoveUser = (query) => {
        getUserListFilter(query, false).then(async userList => {
            this.setState({isEliminarLoading: true});
            const userListJson = await userList.json();

            if (!userList.ok){
                const error = userList.status;
                return Promise.reject(error);
            }

            if (userListJson.hasOwnProperty('data')){
                if (userListJson.data.length > 0){
                    let options = [];
                    this.listaUsuarios = [];
                    for (let i in userListJson.data){
                        const user = new User(userListJson.data[i]);
                        this.listaUsuarios.push(user);
                        options.push({id: user.getId(), loginname: user.getLoginname(), email: user.getEmail(),
                            name: user.getName(), surname: user.getSurname()});
                    }
                    this.setState({removeOptions: options});
                }
            } else {
                refreshIfNeeded(userListJson, null, this.props.showInfoModal);
            }
            this.setState({isEliminarLoading: false});
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.setState({isEliminarLoading: false});
        });
    }

    // Establece el usuario en el servicio de eliminar usuarios.
    removeFormFill = (items) => {
        this.selectedUserRm = "";
        for (let i in this.listaUsuarios){
            if (items.length > 0) {
                if (items[0].id === this.listaUsuarios[i].id) {
                    this.selectedUserRm = new User(this.listaUsuarios[i]);
                    break;
                }
            }
        }

        if (this.selectedUserRm !== ""){
            document.getElementById("infoRemoveUser").classList.remove("d-none");
            document.getElementById("removeUserName").disabled = true;
            document.getElementById("removeUserName").value = this.selectedUserRm.name;
            document.getElementById("removeUserSurname").disabled = true;
            document.getElementById("removeUserSurname").value = this.selectedUserRm.surname;
            document.getElementById("removeUserLoginname").disabled = true;
            document.getElementById("removeUserLoginname").value = this.selectedUserRm.loginname;
            document.getElementById("removeUserEmail").disabled = true;
            document.getElementById("removeUserEmail").value = this.selectedUserRm.email;
            document.getElementById("buttonRemoveUser").classList.remove("d-none");
        }
    }

    // Resetea las opciones del typeahead de eliminar usuarios.
    resetRemoveOptions = () => {
        this.setState({removeOptions: []});
    }

    // Abre el modal de confirmación de eliminar usuarios.
    removeUserConfirmation = () => {
        this.showConfirmationModal(this.props.strings.confirmationModalRemoveTitle, this.props.strings.confirmationModalRemoveBody, this.confirmRemoveUser);
    }

    // Funcionalidad del botón ok para eliminar el usuario.
    checkRemove = (num_a, num_b, callback) => {
        let result = parseInt(document.getElementById("modal-confirmation-result").value);
        //Se resetea el input y el evento onclick.
        this.resetConfirmationModal();
        //Se comprueba el resultado y se redirige a la operacion o se muestra un mensaje de error.
        if (num_a + num_b === result){
            callback();
        } else {
            this.props.showInfoModal(this.props.strings.confirmationModalTitleError, this.props.strings.confirmationModalBodyError);
        }
    }

    // Manda la petición de eliminar usuarios.
    confirmRemoveUser = () => {
        if (this.selectedUserRm !== ""){
            this.props.showLoaderModal(this.props.strings.removingUserMessage);
            let userJson = {};
            let sysAdmin = new SysAdmin(JSON.parse(window.localStorage.getItem(this.props.strings.sysadminKey)));
            userJson.data = new User(this.selectedUserRm);
            userJson.data.generateMetadata("Delete", sysAdmin);

            removeUser(userJson).then(async response => {
                const userListJson = await response.json();

                if (!response.ok){
                    const error = response.status;
                    return Promise.reject(error);
                }

                if (userListJson.hasOwnProperty('data')){
                    document.getElementById("infoRemoveUser").classList.add("d-none");
                    document.getElementById("removeUserName").value = "";
                    document.getElementById("removeUserSurname").value = "";
                    document.getElementById("removeUserLoginname").value = "";
                    document.getElementById("removeUserEmail").value = "";
                    document.getElementById("buttonRemoveUser").classList.add("d-none");
                    this.selectedUserRm = "";
                    this.listaUsuarios = [];
                    this.setState({removeOptions: []});
                    this.props.showInfoModal(this.props.strings.updateModalOkTitle, this.props.strings.updateModalOkBody);
                } else {
                    refreshIfNeeded(userListJson, this.props.hideLoaderModal, this.props.showInfoModal);
                }
               this.props.hideLoaderModal();
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.updateModalErrorTitle, this.props.strings.updateModalErrorBody);
                console.log(error);
                this.props.hideLoaderModal();
            });
        }
    }

    // Espera a que finalicen ambas peticiones para mostrar los datos.
    getStaffUserListAndFarms = () => {
        return Promise.all([this.getStaffUserListPromise(), this.getFarmsPromise()]);
    }

    // Devuelve la promesa de pedir la lista de usuarios relaccionados.
    getStaffUserListPromise = () => {
        return getStaffUserList(this.selectedUserIn.getId(), this.state.page_size, this.state.page, this.state.query, this.state.select).then(async userList => {
            const usersJson = await userList.json();
            this.setState({maxamount: parseInt(userList.headers.get("content-range").split(" ")[2])});

            if (!userList.ok){
                const error = userList.status;
                return Promise.reject(error);
            }

            if (usersJson.hasOwnProperty('data')){
                let users = [];
                for (let i in usersJson.data) {
                    users.push(new UserStaff(usersJson.data[i]));
                }
                this.fillUserListTable(users);
            } else {
                refreshIfNeeded(usersJson, null, this.props.showInfoModal);
            }
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
        });
    }

    // Devuelve la promesa de pedir el número de explotaciones del usuario.
    getFarmsPromise = () => {
        return getUserFarms(this.selectedUserIn.getId()).then(async userFarms => {
            const farmsJson = await userFarms.json();

            if (!userFarms.ok){
                const error = userFarms.status;
                return Promise.reject(error);
            }

            if (farmsJson.hasOwnProperty('data')) {
                document.getElementById("userFarms").innerHTML = farmsJson.data.toString();
            } else {
                refreshIfNeeded(farmsJson, null, this.props.showInfoModal);
                document.getElementById("userFarms").innerHTML = "0";
            }
        }).catch(error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
        });
    }

    // Rellena la tabla con la lista de usuarios pasados.
    fillUserListTable = (users) => {
        let newTableBody = [];
        for (let i in users){
            newTableBody.push({id: users[i].getUserId(), username: users[i].getUserLoginname(), email: users[i].getUserEmail(),
                name: users[i].getUserName(), surname: users[i].getUserSurname(), nif: users[i].getUserNif()});
        }
        this.setState({tablebody: newTableBody, petitionended: true, isLoading: false}, () => {
            document.getElementById("userAmount").innerText = this.props.strings.amount1 + this.state.maxamount + this.props.strings.userAmount2;
        });
    }

    // Descargar el excel con los usuarios.
    downloadUsers = () => {
        this.showDownloadLoader();
        getAllUserList().then(async usersAsync => {
            const usersAsyncJson = await usersAsync.json();

            if (!usersAsync.ok){
                const error = usersAsync.status;
                return Promise.reject(error);
            }

            if (usersAsyncJson.hasOwnProperty('data')){
                let jobAsync = new JobAsync(usersAsyncJson.data);
                // Se realiza la petición de la tarea asíncrona para obtener el resultado cuando esté disponible.
                this.getJobAsyncUsers(jobAsync.id, this.props.globals.getMinTimeout());
            } else {
                refreshIfNeeded(usersAsyncJson, this.hideDownloadLoader, this.props.showInfoModal);
            }
        }).catch( error => {
            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
            console.log(error);
            this.hideDownloadLoader();
        });
    }

    // Petición asíncrona de usuarios.
    getJobAsyncUsers = (id, timeout) => {
        setTimeout(() => {
            getJobAsync(id).then(async jobPromise => {
                const jobPromiseJson = await jobPromise.json();

                if (!jobPromise.ok){
                    const error = jobPromise.status;
                    return Promise.reject(error);
                }

                if (jobPromiseJson.hasOwnProperty('data')) {
                    let jobAsyncCheck = new JobAsync(jobPromiseJson.data);
                    if (jobAsyncCheck.hasFinished()) {
                        getJobAsyncResultAmazon(jobAsyncCheck.result).then(jobAnswer => jobAnswer.json()).then(jobAnswerJson => {
                            if (jobAnswerJson.hasOwnProperty('data')){
                                this.createUserExcel(jobAnswerJson.data);
                            } else {
                                refreshIfNeeded(jobAnswerJson, this.hideDownloadLoader, this.props.showInfoModal);
                            }
                        }).catch(error => {
                            this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                            console.log(error);
                            this.hideDownloadLoader();
                        });
                        this.hideDownloadLoader();
                    } else {
                        if (!this.state.exitPetition) {
                            timeout = ((timeout * 2) > this.props.globals.getMaxTimeout() ? this.props.globals.getMaxTimeout() : timeout * 2);
                            this.getJobAsyncUsers(id, timeout);
                        }
                    }
                } else {
                    refreshIfNeeded(jobPromiseJson, this.hideDownloadLoader, this.props.showInfoModal);
                }
            }).catch(error => {
                this.props.showInfoModal(this.props.strings.getModalErrorTitle, this.props.strings.getModalErrorBody);
                console.log(error);
                this.hideDownloadLoader();
            });
        }, timeout);
    }

    // Muestra el loader de descargar usuarios y oculta su form.
    showDownloadLoader = () => {
        document.getElementById("downloadFormLoader").classList.remove("d-none");
        document.getElementById("downloadForm").classList.add("d-none");
    }

    // Oculta el loader de descargar usuarios y muestra su form.
    hideDownloadLoader = () => {
        document.getElementById("downloadFormLoader").classList.add("d-none");
        document.getElementById("downloadForm").classList.remove("d-none");
    }

    // Crea el excel descargable de usuarios.
    createUserExcel = (body) => {
        const ExcelJS = require('exceljs');
        const FileSaver = require('file-saver');
        let excelTitle = this.props.strings.userSummary + datetimeToString(new Date())  + this.props.strings.excelFormat;
        let userExcel = new ExcelJS.Workbook();
        // noinspection JSUnresolvedFunction
        userExcel.addWorksheet(this.props.strings.osigrisExcel, { views: [{ activeCell: 'A1', showGridLines: true }] });
        let worksheet = userExcel.getWorksheet(1);
        worksheet.columns = [
            { key: 'id', width: 15 },
            { key: 'fecha', width: 20 },
            { key: 'client_id', width: 20 },
            { key: 'nombre', width: 20 },
            { key: 'apellido', width: 25 },
            { key: 'farms', width: 25 },
            { key: 'email', width: 25 },
            { key: 'nif', width: 15 },
            { key: 'telefono', width: 15 },
            { key: 'empresa', width: 20 },
            { key: 'org', width: 20 }
        ];
        worksheet.getRow(1).values = [this.props.strings.tableId, this.props.strings.tableDate,
            this.props.strings.tableUsername, this.props.strings.tableName,
            this.props.strings.tableSurname, this.props.strings.tableNSeason,
            this.props.strings.tableEmail, this.props.strings.tableNIF,
            this.props.strings.tablePhone, this.props.strings.tableCompany,
            this.props.strings.tableOrganization];
        ['A1', 'B1', 'C1', 'D1', 'E1', 'F1', 'G1', 'H1', 'I1', 'J1', 'K1'].map(key => {
            worksheet.getCell(key).fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor:{ argb:'cccccc' }
            };
            worksheet.getCell(key).alignment = {
                vertical: 'middle',
                horizontal: 'center'
            };
            return null;
        });
        body = this.orderUsers(body);
        for (let i in body){
            let user = new User(body[i]);
            worksheet.addRow([user.id, (user.metadata.length > 0 ? user.metadata[0].date : ""), user.loginname, user.name,
                user.surname, user.nf, user.email, user.nif, user.phone, user.company, (user.key !== null ? (user.key.getOrganization() !== null ? user.key.getOrganization().getName() : "") : "")]);
        }
        userExcel.xlsx.writeBuffer().then(data => {
            // noinspection JSCheckFunctionSignatures, JSUnresolvedVariable
            const blob = new Blob([data], { type: this.blobType });
            // noinspection JSUnresolvedFunction
            FileSaver.saveAs(blob, excelTitle);
            this.hideDownloadLoader();
        });
    }

    // Ordena elementos dependiendo del campo id.
    orderUsers = (body) => {
        body = body.sort((a, b) => (parseInt(a.id) > parseInt(b.id)) ? 1 : -1);
        return body;
    }

    // Abre el modal de confirmacion con los argumentos pasados como título, cuerpo y función de retorno.
    showConfirmationModal = (title, content, callback) => {
        this.setState({showModalConfirmation: true});
        //Se obtienen los dos numeros para la operacion de confirmacion.
        let num_a = Math.floor(Math.random() * 100);
        let num_b = Math.floor(Math.random() * 100);
        //Se muestra la informacion en el modal.
        setTimeout(() => {
            document.getElementById("modal-confirmation-title").innerHTML = title;
            document.getElementById("modal-confirmation-content").innerHTML = content + " " + this.props.strings.confirmationModalOperation;
            document.getElementById("modal-confirmation-operation").innerHTML = num_a + " + " + num_b + " = ";
            //Se establece el evento onclick del boton de confirmacion.
            document.getElementById("modal-confirmation-button-ok").onclick = () => {this.checkRemove(num_a, num_b, callback)};
            document.getElementById("modal-confirmation-button-cancel").onclick = () => {this.resetConfirmationModal()};
        }, 100);
    }

    // Resetea el modal de confirmación.
    resetConfirmationModal = () => {
        document.getElementById("modal-confirmation-result").value = "";
        document.getElementById("modal-confirmation-button-ok").removeAttribute("onclick");
        document.getElementById("modal-confirmation-button-cancel").removeAttribute("onclick");
        this.hideConfirmationModal();
    }

    // Oculta el modal de confirmación.
    hideConfirmationModal = () => {
        this.setState({showModalConfirmation: false});
    }
}

export default UserManagement;